<template>
  <svg class="tooltip-icon" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-help">
    <path id="Vector" d="M10.2425 2.2573C9.15867 1.17256 7.65487 0.499672 6 0.5C4.34515 0.499672 2.84116 1.17256 1.75739 2.2573C0.672563 3.34116 -0.000328005 4.84515 1.19949e-07 6.5C-0.000328005 8.15487 0.672563 9.65886 1.75739 10.7427C2.84123 11.8274 4.34515 12.5004 6 12.5C7.65487 12.5004 9.15867 11.8274 10.2425 10.7427C11.3274 9.65886 12.0004 8.15487 12 6.5C12.0004 4.84515 11.3274 3.34116 10.2425 2.2573ZM9.36267 3.13733C10.2241 3.99983 10.7552 5.18473 10.7555 6.5C10.7552 7.81529 10.2241 9.00017 9.36267 9.86267C8.50017 10.7243 7.3151 11.2552 6 11.2555C4.68483 11.2552 3.49983 10.7243 2.63733 9.86267C1.77577 9.00017 1.24479 7.81529 1.24444 6.5C1.24479 5.18473 1.77577 3.99983 2.63733 3.13733C3.49983 2.2757 4.6848 1.74479 6 1.74444C7.31512 1.74481 8.50017 2.2757 9.36267 3.13733Z" fill="#3282C5"/>
    <path id="Vector_2" d="M5.68947 8.21863C5.35647 8.21863 5.08643 8.4886 5.08643 8.82158C5.08643 9.15421 5.35647 9.42435 5.68947 9.42435C6.02219 9.42435 6.29215 9.15421 6.29215 8.82158C6.29215 8.4886 6.02219 8.21863 5.68947 8.21863Z" fill="#3282C5"/>
    <path id="Vector_3" d="M4.32093 4.57455L4.73144 4.90354C4.81502 4.97039 4.93474 4.96795 5.01546 4.89764C5.01546 4.89764 5.0659 4.80649 5.22396 4.71621C5.38282 4.62644 5.58888 4.55423 5.8968 4.55318C6.16544 4.55266 6.39975 4.65283 6.55957 4.78983C6.6389 4.85789 6.69827 4.93357 6.73465 5.00334C6.77128 5.07333 6.78464 5.13459 6.78447 5.18111C6.78377 5.3384 6.75314 5.44136 6.70896 5.52886C6.67537 5.59448 6.63154 5.65247 6.57501 5.70872C6.49073 5.79309 6.37622 5.87104 6.24794 5.94274C6.11946 6.01497 5.98101 6.07886 5.84109 6.15593C5.68153 6.24447 5.51245 6.37141 5.38769 6.562C5.32537 6.65611 5.27667 6.76408 5.24568 6.87761C5.21442 6.99168 5.20029 7.11096 5.20029 7.23265C5.20029 7.36251 5.20029 7.46927 5.20029 7.46927C5.20029 7.5915 5.29959 7.69099 5.42208 7.69099H5.95617C6.07856 7.69099 6.17779 7.5915 6.17779 7.46927C6.17779 7.46927 6.17779 7.36251 6.17779 7.23265C6.17779 7.18577 6.18318 7.1554 6.18839 7.13646C6.19715 7.10765 6.2021 7.1005 6.2167 7.08297C6.23137 7.06631 6.26114 7.04097 6.31601 7.0106C6.39614 6.96562 6.52495 6.90485 6.67087 6.82586C6.88936 6.70643 7.15481 6.54445 7.37878 6.27691C7.49006 6.14325 7.5885 5.98263 7.65675 5.79757C7.72514 5.61232 7.76229 5.404 7.76196 5.18107C7.76177 4.9552 7.70051 4.74044 7.60066 4.54999C7.45031 4.26389 7.21472 4.02569 6.92287 3.85277C6.63112 3.68072 6.27904 3.57569 5.89685 3.57569C5.42583 3.57447 5.03442 3.69738 4.73913 3.86735C4.4425 4.03661 4.31465 4.23365 4.31465 4.23365C4.26483 4.27706 4.2367 4.33992 4.23783 4.40571C4.23926 4.47178 4.26954 4.53358 4.32093 4.57455Z" fill="#3282C5"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHelp',
};
</script>
